import { h, shallowRef } from 'vue'
import * as echarts from 'echarts'

export default {
  name: 'ELine',
  props: {
    source: Array,
    loading: Boolean,
    styles: String
  },
  data () {
    return {
      chart: shallowRef(null)
    }
  },
  created () {
    this.$watch('loading', e => {
      if (!e) {
        this.init()
      }
    })

    this.$watch(
      () => this.source,
      e => {
        this.chart && this.updateOption()
      },
      { deep: true }
    )
  },
  methods: {
    init () {
      this.chart = echarts.init(this.$el)
      this.setOption()
    },

    updateOption () {
      this.chart.setOption({
        dataset: {
          source: this.source
        }
      })
    },

    setOption () {
      const option = {
        legend: {
          show: true,
          right: 0
        },
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        color: ['#749fff'],
        grid: {
          left: '50',
          right: '20'
        },
        dataset: {
          source: this.source
        },
        xAxis: { type: 'category' },
        yAxis: {
          splitLine: { show: false },
          axisLine: {
            show: true
          },
          type: 'value'
        },
        series: [
          {
            type: 'line',
            name: '流水',
            smooth: true,
            symbol: 'circle',
            symbolSize: 2,
            areaStyle: {}
          }
        ]
      }

      this.chart.setOption(option)
    }
  },
  render () {
    return (
      h('div', {
        style: this.styles
      }, '')
    )
  }
}
